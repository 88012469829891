import { connect } from '@dazn/depo-react';
import Environment from '@shared/Constants/Environment';
import * as React from 'react';
import { Link } from 'react-router-dom';

import StoreName from '../../State/Shared/Constants/StoreName';
import Logo from '../Logo/Logo';
import ISelectOption from '../Select/Interfaces/ISelectOption';
import Select from '../Select/Select';

import * as styles from './header.css';

const mapStateToProps = (value, actions) => ({
    config: value[StoreName.CONFIG],
    setEnvironmentAction: actions[StoreName.CONFIG].setEnvironment
});

class Header extends React.Component<ReturnType<typeof mapStateToProps>> {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    private get options(): ISelectOption[] {
        const { config } = this.props;

        const options = Object.keys(config.environments).map((environment) => ({
            value: environment,
            label: config.environments[environment]
        }));

        return options;
    }

    public render(): JSX.Element {
        return (
            <header className={styles.header}>
                {
                    // we want a hard reload here, hence not using Link
                }
                <a href="/">
                    <Logo className={styles.logo} />
                </a>
                <div className={styles.environmentMenu}>
                    <Select
                        options={this.options}
                        onChange={this.handleChange}
                        value={this.props.config.currentEnvironment}
                        liveUpdates
                        showPlaceholderWhenOpen
                        placeholder="Select environment"
                    />
                </div>
            </header>
        );
    }

    private handleChange(e: React.ChangeEvent<Element>): void {
        const target = e.target as HTMLSelectElement;

        this.props.setEnvironmentAction(target.value as Environment);
    }
}

export default connect(mapStateToProps)(Header);
